import styled from "styled-components";
import Background from "../../assets/background-roxo.jpg"

export const IwatchLandingStyle = styled.div`
    font-family: Poppins;

    .div-roxa {
            position: relative;
            width: 90%;
            background-color: rebeccapurple;
            background-image: url(${Background});
            border-radius: 30px;
            padding: 90px 20px 60px;
            background-size: cover; 
            background-position: center; 
            background-repeat: no-repeat; 
            margin: 0 auto; 

            button{
                border: none;
                padding: 10px 20px;
                border-radius: 42px;
                background: #FFF;
                margin-top: 40px;
                color: #742282;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                width: max-content;
                display: flex;
                margin: 30px auto 0px;
                position: relative;
                z-index: 2;
            }
        }

        .relative-input{
            position: relative; 

            span{
                color: red;
                margin: 13%;
            }
        }

    .body{
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }    

    h1{
        font-size: 9vw;
        font-weight: 500;
        color: white;
        line-height: 1.3;
    }

    h2{
        font-size: 9vw;
        font-weight: 100;
        color: white;
        line-height: 1;
    }

    h5{
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        margin-top: 20px;

        strong{
            color: white;
        }
    }

    .celular{
        position: relative;
        width: 113%;
        transform: translate(calc(7% - 2px), 10%);
        top: 15%;
        border-radius: 20px 0px 0px 20px;
        right: 0;
    }

    .div-roxa-redonda{
        position: relative;
        background: #A855BD;
        display: flex;
        flex-direction: column;
        width: 260px;
        height: 260px;
        border-radius: 999px;
        align-items: center;
        margin: 0 auto;
        
        span{
            color: #FFF;
            font-size: 16px;
            font-weight: 500;
            margin-top: 20px;
        }

        h5{
            margin: 0px;
            font-weight: 500;
        }

        .flex{
            display: flex;
            margin-top: 60px;

            h3{
                color: #FFF;
                font-weight: 700;
                font-size: 200px;
                font-family: "Radio Canada";
                margin-top: -130px;
            }

            .margin-top-dinheiro{
                margin-top: -82px;
            }
            
            h4{
                position: relative;
                color: #FFF;
                font-weight: 700;
                font-size: 40px;
                font-family: "Radio Canada";
                right: 48px;
                top: 20px;
            }

            h6{
                position: absolute;
                right: 20%;
                bottom: 13%;
                color: white;
                font-size: 37px;
                font-weight: 700;
            }

            .h5-pt{
                margin-left: -16px;
            }            
        }

        .dinheiro{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            right: -30px;
        }
    }

    .span-custo{
        display: flex;
        margin: 0 auto;
        font-size: 14px;
        color: #FFF;
        margin-top: 20px;
    }

    .cards{
        position: relative;
        top: -30px;
    }

    .card{
        width: 80%;
        display: flex;
        align-items: center;
        padding: 40px 30px;
        border-radius: 30px;
        color: #333333;
        border: 1px solid #D9D9D9;
        margin: 0 auto;
        background: #FFF;
        margin-bottom: 17px;

        h3{
            font-size: 14px;
            font-weight: 300;
            margin-left: 20px;
            width: 150px;
            color: #333333;

            strong{
                font-size: 14px;
                font-size: 500;
                color: #333333;
            }
        }
    }

    .botao-azul{
        width: 272px;
        height: 46px;
        border-radius: 42px;
        background: #009FE3;
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        border: none;
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }

    .div-ping{
        position: relative;
        width: 90%;
        background-color: rebeccapurple;
        background-image: url(${Background});
        border-radius: 30px;
        padding: 90px 20px 60px;
        background-size: cover; 
        background-position: center;
        background-repeat: no-repeat;
        margin: 5% auto 0px; 
        display: flex;
        flex-direction: column;
        align-items: center;

        h2{
            font-size: 80px;
        }

        h4{
            color: #FFF;
            font-size: 25px;
            font-weight: 300;
            margin-top: 20px;
            line-height: normal;
            text-align: center;
            width: 260px;
        }
    }

    .h5-form{
        color: #333333;
        font-size: 9vw;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 50px auto 0px;
        width: 80%;

        strong{
            font-size: 10vw;
            font-weight: 500;
            color: #009FE3;
        }
    }

    .h6-form{
        color: #333333;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 20px auto 0px;
        width: 80%;
        text-align: center;
        font-size: 3.3vw; 
    }

    input{
        border-radius: 12px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        width: 80%;
        padding: 25px 30px;
        margin: 16px auto;
        display: flex;
        font-size: 18px;
    }

    textarea{
        border-radius: 12px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        width: 80%;
        max-width: 80%;
        min-width: 80%;
        padding: 25px 30px;
        margin: 16px auto;
        display: flex;
        font-size: 18px;
        max-height: 500px;
    }

    .margin-top-dinheiro{
        margin-top: 20px;
    }

    .botao-roxo{
        border: none;
        border-radius: 42px;
        background: #742282;
        padding: 15px 0px;
        width: 60%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-size: 16px;
        max-width: 265px;
    }

    .balao{
        position: absolute;
        opacity: 1.4;
        left: -13px;
    }

    .width83{
        width: 83%;
    }

    .div-cinza{
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 50px auto 0px;
        border-radius: 30px;
        background-color: #F1F1F1;
        align-items: center;
        padding: 40px 0px 60px;
        
        h2{
            width: 100%;
            font-size: 8vw;
            font-weight: 300;
            color: #333333;
            line-height: normal;
            
            strong{
                font-size: 8vw;
                color: #742282;
                font-weight: 500;
            }
        }

        .div-img{    
            display: flex;
            width: 100%;
            justify-content: flex-start;
        }

        h3{
            font-size: 3.3vw;
            font-weight: 300;
            color: #333333;
            margin: 20px 0px 40px;
            width: 100%;
            display: flex;
            flex-direction: column;
            line-height: 1.5;
        
            strong{
                font-size: 3.3vw;
                font-weight: 500;
            }
        }

        h5{
            position: relative;
            z-index: 2;
            font-size: 16px;
            font-weight: 300;
            color: #333333;
            margin-top: 20px;
            width: 100%;

            strong{
                font-weight: 500;
                color: #333333;
            }

            .aspas-esquerda{
                position: absolute;
                z-index: -1;
                top: -46px;
                left: -12px;
            }

            .aspas-direita{
                position: absolute;
                z-index: -1;
                bottom: -30px;
                right: -6px;
            }
        }
    }

    footer{
        width: 90%;
        margin: 50px auto 0px;
        border-radius: 30px 30px 0px 0px;
        background-image: url(${Background});
        background-size: cover; 
        background-position: center; 
        background-repeat: no-repeat; 
        padding: 20px 0px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        
        .logo-ifollow{
            display: flex;
            justify-content: center;
            margin: 40px auto;
        }
        
        span{
            display: flex;
            color: #FFF;
            font-family: Poppins;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            justify-content: center;
        }
    }

    .imagens{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 200px;
            margin: 0 auto;
    }

    .width90{
        width: 90%;
        margin: 20px auto;
    }

    .desktop{
        display: none;
    }

    header{
        width: 100%;
        display: flex;
        margin: 0 auto;
        align-items: flex-end;
        justify-content: space-between;
        position: fixed;
        z-index: 3;
        top: 0px;
        background: #FFF;
        padding: 8px 0px 8px;

        .width90{
            background: #FFF;
            z-index: 1;
            width: 90%;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            padding: 8px 0px 8px;
        };

        .logo{
            width: 100px;
            height: 35px;
        }

        .bandeira{
            width: 35px;
            background: #D9D9D9;
            height: 35px;
            padding: 7px;
            border-radius: 100px;
            margin-right: 10px;
        }

        button{
            border: none;
            background-color: #742282;
            color: #FFF;
            font-size: 12px;
            font-weight: 600;
            border-radius: 42px;
            padding: 8px 7px;
        }
    }

    .div-header-mobile{
        display: flex;
        align-items: flex-end;
    }

    .div-header-desktop{
        display: none;
    }

    label{
        position: relative;
        bottom: 46px;
        font-size: 44px;
        font-weight: 800;
        color: #742282;
    }

    .whatsapp{
        position: fixed;
        right: 5%;
        bottom: 100px;
        z-index: 9999;
    }

    @media(min-width: 1024px){
        .desktop{
            display: flex;
        }

        .mobile{
            display: none;
        }

        .div-header-desktop{
            display: flex;
            align-items: center;
        }

        .div-header-mobile{
            display: none;
        }

        label{
            position: relative;
            bottom: 16px;
            left: 15px;
            font-size: 26px;
            font-weight: 900;
            color: #742282;
        }

        header{
            button{
                border: none;
                background-color: #742282;
                color: #FFF;
                font-size: 16px;
                font-weight: 600;
                border-radius: 42px;
                padding: 23px 18px;
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    width: 20px;
                    margin-right: 5px;
                }
            }

            .logo{
                width: 150px;
                height: 54px;
            }
        }

        .div-cinza-botoes{
            background-color: #D9D9D9;
            padding: 5px;
            width: 295px;
            display: flex;
            justify-content: space-between;
            border-radius: 30px;
            margin-right: 30px;

            button{
                background-color: #EBEBEB;
                width: 135px;
                padding: 4px 0px;
                color: #333333;
                opacity: 0.4;
                font-weight: 300;
            }

            .selecionado{
                opacity: 1;
            }
        }

        .botao-roxo{
            font-size: 16px;
        }

        .celular{
            border-radius: 20px;
        }

        .span-custo{
            position: absolute;
            right: 21px;
            bottom: 50px;
        }

        .flex-div-roxa{
            display: flex;

            img{
                top: 0;
                right: -40%;
                transform: none;
                width: 50%;
                border-radius: 30px;
            }
        }

        .balao{
            height: 75%;
        }

        .relative-imagem{
            position: relative;
            width: 50%;
            
            img{
                width: 100%;
            }
        }

        .div-roxa-redonda{
            position: absolute;
            width: 178px;
            height: 178px;
            bottom: 10px;
            right: 12vw;

            span{
                font-size: 13px;
            }
        }

        .div-roxa-redonda .flex {
            display: flex;
            margin-top: 20px;

            h3{
                font-size: 110px;
                margin-top: -57px;
            }

            .margin-top-dinheiro{
                margin-top: -32px;
            }

            h4{
                position: relative;
                color: #FFF;
                font-weight: 700;
                font-size: 22px;
                font-family: "Radio Canada";
                right: 30px;
                top: 22px;
            }

            h5{
                font-size: 12px;
                margin-top: 12px;
                width: 111px;
                margin-left: 19px;
            }

            h6{
                right: 20%;
                bottom: 18%;
                font-size: 34px;
            }

            .h5-pt{
                margin-left: 16px;
            }    
        }

        .div-roxa{
            width: 80%;
            margin: 0px 0px 0px 5%;
            padding: 90px 20px;

            h1{
                font-size: 6vw;
                min-width: max-content;
            }
            
            h2{
                font-size: 6vw;
                min-width: max-content;
            }

            button{
                margin: 20px 0px 0px;
                position: relative;
                z-index: 2;
            }
        }

        .textos-div-roxa{
            width: 50%;

            h5{
                font-size: 2vw;

                strong{
                    font-size: 2vw;
                }
            }
        }

        .cards{
            display: flex;
            justify-content: space-between;
            width: 90%;
            margin: 0px 0px 0px 5%;
        }

        .card{
            display: flex;
            flex-direction: column;
            width: 151px;
            height: 140px;
            min-width: 151px;
            min-height: 140px;
            text-align: center;
            padding: 20px 10px;

            h3{
                font-size: 14px; 
                margin: 0px;
            }
        }

        .div-ping{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 60px 30px;

            h4{
                font-size: 2.5vw;
                width: max-content;
                margin: 0px 0px 0px 20px;
            }
        }

        .div-form{
            display: flex;
            width: 90%;
            justify-content: space-evenly;
            align-items: baseline;
            margin: 0 auto;

            form{
                width: 50%;
            }

            input{
                padding: 20px 30px;
            }
        }

        .textos-form{
            display: flex;
            width: 50%;
            flex-direction: column;
            

            h5{
                width: 70%;
                font-size: 3.9vw;
                
                strong{
                    font-size: 3.9vw;
                }
            }

            h6{
                width: 69%;
                font-size: 1.3vw;
                text-align: start;

                strong{
                    font-size: 1.3vw;
                }
            }
        }

        .div-cinza{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 70px 0px 70px;

            .width83{
                display: flex;
                align-items: center;
                justify-content: space-between;

                .textos, .column{
                    min-width: 40%;
                }

                .nome-fini{
                    display: flex;
                    align-items: center;

                    .div-img{
                        width: max-content;
                    }

                    img{
                        width: 100px;
                        height: 50px;
                    }
                    h3{
                        margin: 0px 0px 0px 20px;
                        font-size: 1.2vw;

                        strong{
                            font-size: 1.2vw;
                        }
                    }
                }
            }

            h2{
                font-size: 3.6vw;
                width: 98%;
                margin-bottom: 50px;

                strong{
                    font-size: 3.6vw;
                }
            }

            h3{
                font-size: 20px;
                width: 98%;
            }

            .column{
                min-width: 45%;
            }

            .textos{
                width: 45%;
            }
        }

        footer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px 40px;
        }

        .imagens{
            justify-content: flex-end;
        }

        .div-logo{
            width: 300px;
        }

        .div-final{
            width: 40%;
            margin-top: 100px;

            img{
                margin-left: 20px;
            }
        }
    }

    @media(min-width: 1200px){
        .div-roxa-redonda{
            right: 13vw;
            width: 170px;
            height: 170px;

            .flex h6{
                bottom: 14%;
            }
        }
    }
    
    @media(min-width: 1500px){
        .div-roxa-redonda{
            right: 13vw;
            width: 200px;
            height: 200px;

            .flex{
                margin-top: 21px;
            }
        
            .flex h3{
                font-size: 130px;
                margin-top: -65px;
                margin-right: -9px;
            }

            .flex .margin-top-dinheiro{
                margin-top: -37px;
            }

            .flex h4{
                top: 30px;
            }

            .flex h5{
                margin-top: 20px;
            }

            .flex h6{
                bottom: 20%;
            }   
        }

        .card{
            display: flex;
            flex-direction: column;
            width: 200px;
            height: 200px;
            text-align: center;
            padding: 20px 10px;
            justify-content: center;
            width: 16%;
            aspect-ratio: 1 / 1;

            img{
                width: 60px;
                height: 60px;
            }

        }
    }
    
    @media(min-width: 1900px){
        .div-roxa-redonda{
            right: 13vw;
            width: 245px;
            height: 245px;

            .flex{
                margin-top: 59px;

            h6{
                right: 20%;
                bottom: 20%;
            }
            }
        
            .flex h3{
                font-size: 160px;
                margin-top: -100px;
                margin-right: 3px;
            }

            .flex h4{
                position: relative;
                color: #FFF;
                font-weight: 700;
                font-size: 28px;
                font-family: "Radio Canada";
                right: 40px;
                top: 22px;
            }

            .flex h5{
                font-size: 16px;
                margin-top: 0px;
                width: 126px;
                margin-left: 10px;
            }

            .flex{
                label{
                    top: 58px
                }

                .margin-top-dinheiro{
                    margin-top: -71px;
                    margin-right: 9px;
                }
            }

            span{
                font-size: 16px;
            }
        }

        label{
            position: absolute;
            top: 69px;
            left: 106px;
            font-size: 56px;
            font-weight: 900;
            color: #742282;
        }
    }

`