export const jsonMockudo = {
    "português" : {
        es : "portugués",
        in : "português"
    },
    "espanhol" : {
        es : "español",
        in : "spanish"
    },
    "A Fini tem muita relevância como marca nos pontos de venda do Brasil e investimos bastante em manter essa fortaleza frente à concorrência através de visibilidade com nossos materiais." : {
        es : "Fini tiene mucha relevancia como marca en los puntos de venta de Brasil, y invertimos mucho en mantener esa fortaleza frente a la competencia a través de la visibilidad de nuestros materiales.",
        in : "Fini has a lot of relevance as a brand in Brazil's points of sale, and we invest a lot in maintaining that strength against the competition through the visibility of our materials."
    },
    "Mais controle,"
    : {
        es : "¡Más control,",
        in : "More control,"
    },
    "menos custos!" : {
        es : "menos costos!",
        in : "less costs!"
    },
    "Com o iWatch você acompanha remotamente a" : {
        es : "Con iWatch sigues remotamente la",
        in : "With iWatch you remotely follow the"
    },
    "dos seus materiais direto pelo celular" : {
        es : "de tus materiales directamente desde su móvil. ",
        in : "of your materials directly from your cell phone"
    },
    "Preencha o formulário ao lado, e um de nossos especialistas entrará em contato para apresentar a solução ideal para sua empresa!": {
            es : "Rellena el formulario al lado, y uno de nuestros especialistas se pondrá en contacto para presentarte la solución ideal para tu empresa."
        },
    "saiba mais " : {
        es : "saber más ",
        in : "know more "
    },
    "quero para o meu negócio " : {
        es : "quiero para mi negocio ",
        in : "I want for my business "
    },
    " localização " : {
        es : " ubicación ",
        in : " location "
    },
    "Localização" : {
        es : "Ubicación",
        in : " Location "
    },
    "a cada leitura" : {
        es : "a cada lectura",
    },
    "Pode ser feito por" : {
        es: "Se puede hacer con",
    },
    " qualquer dispositivo " : {
        es : " cualquier dispositivo ",
    },
    "com câmera" : {
        es : "con cámara",
    },
    " Baixo custo! " : {
        es : "¡Bajo costo!",
    },
    "Apenas R$1,00 por leitura" : {
        es : "Solo R$1,00 por lectura",
    },
    "Rápido e fácil" : {
        es : "Rápido y fácil",
    },
    "de implementar" : {
        es : "de implementar",
    },
    "Suporte 24/7" : {
        es : "Soporte 24/7",
    },
    "para toda a equipe" : {
        es : "para todo el equipo",
    },
    "quero rastrear meu material " : {
        es : "quiero rastrear mi material ",
    },
    "Por apenas" : {
        es : "Por solo",
    },
    "você rastreia o seu material" : {
        es : "rastreas tu material",
    },
    "* Custo de cada leitura realizada" : {
        es : "* Costo de cada lectura realizada",
    }, 
    "Precisa de mais" : {
        es : "¿Necesitas más",
    },
    " visibilidade" : {
        es : " visibilidad ",
    },
    "dos seus materiais?" : {
        es : "de tus materiales?",
    },
    "Preencha o formulário abaixo, e um de nossos especialistas entrará em contato para apresentar a solução ideal para sua empresa!" : {
        es : "Rellena el formulario a continuación, y uno de nuestros especialistas se pondrá en contacto para presentarte la solución ideal para tu empresa.",
    },
    "Nome" : {
        es : "Nombre",
    },
    "Empresa" : {
        es : "Empresa",
    },
    "E-mail" : {
        es : "Correo electrónico",
    },
    "Telefone" : {
        es : "Teléfono",
    },
    "enviar " : {
        es : "enviar ",
    },
    "Solução" : {
        es : "¡Solución",
    },
    " testada e recomendada " : {
        es : "probada y recomendada",
    },
    "por quem usa!" : {
        es : "por quien usa!",
    },
    "Victor Henrique" : {
        es : "Victor Henrique",
    },
    "Analista de Trade Marketing | Operações" : {
        es : "Analista de Trade Marketing | Operaciones",
    },
    "A Fini tem muita relevância como marca nos pontos de venda do Brasil e investimos bastante em manter essa fortaleza frente à concorrência através de visibilidade com nossos materiais." : {
        es : "Fini tiene mucha relevancia como marca en los puntos de venta de Brasil, y invertimos mucho en mantener esa fortaleza frente a la competencia a través de la visibilidad de nuestros materiales.",
    },
    "Até ano de 2023, não tínhamos visão de onde nossos materiais estavam sendo destinados, ou seja, para onde estava indo o investimento da companhia que acabava ficando no limbo?" : {
        es : "Hasta el año 2023, no teníamos una visión de dónde se destinaban nuestros materiales, es decir, ¿a dónde iba la inversión de la compañía que terminaba en el limbo?",
    },
    " Quando conhecemos o time da Ifollow, foi como uma sensação de alívio para nós, pois conseguimos pelo menos saber onde estavam sendo positivados nossos displays de sazonalidade Junina. " : {
        es : "Cuando conocimos al equipo de Ifollow, fue como una sensación de alivio para nosotros, ya que al menos pudimos saber dónde se estaban positivando nuestros displays de temporada de San Juan.",
    },
    "Através desse norte, pudemos rever nossas estratégias para as próximas campanhas" : {
        es : "A través de este norte, pudimos revisar nuestras estrategias para las próximas campañas",
    },
    "Deu tão certo esse piloto que estamos fechando parceria de sucesso para demais campanhas de 2024." : {
        es : "Este piloto funcionó tan bien que estamos cerrando una asociación exitosa para las demás campañas de 2024.",
    },
    "+3500" : {
        es : "+3500",
    },
    "materiais rastreados nos últimos meses" : {
        es : "materiales rastreados en los últimos meses",
    },
    "iFollow Desenvolvimento e Licenciamento de Software LTDA | CNPJ 34.574.801/0001-82" : {
        es : "iFollow Desarrollo y Licenciamiento de Software LTDA | CNPJ 34.574.801/0001-82",
    },
    "+55 (11) 2476-2245" : {
        es : "+55 (11) 2476-2245",
    },
    "O Nome é obrigatório": {
        es : "El nombre es obligatorio",
    },
    "O Nome deve ter no máximo 250 caracteres": {
        es : "El nombre debe tener como máximo 250 caracteres",
    },
    "Digite um E-mail válido": {
        es : "Ingrese un correo electrónico válido",
    },
    "O E-mail é obrigatório": {
        es : "El correo electrónico es obligatorio",
    },
    "O E-mail deve ter no máximo 250 caracteres": {
        es : "El correo electrónico debe tener como máximo 250 caracteres",
    },
    "O Telefone é obrigatório": {
        es : "El teléfono es obligatorio",
    },
    "O Telefone deve ter no máximo 250 caracteres": {
        es : "El teléfono debe tener como máximo 250 caracteres",
    },
    "A Empresa é obrigatória": {
        es : "La empresa es obligatoria",
    },
    "A Empresa deve ter no máximo 250 caracteres": {
        es : "La empresa debe tener como máximo 250 caracteres",
    },
    "O campo é obrigatório": {
        es : "El campo es obligatorio",
    },
}