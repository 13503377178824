import IwatchLanding from "../../components/Iwatch/";
import { IwatchLandingStyle } from "./style";

const IwatchLandingPage = () => {

    return (
        <IwatchLandingStyle>
            <IwatchLanding/>
        </IwatchLandingStyle>
    )
}

export default IwatchLandingPage;