import { IwatchLandingStyle } from "./style.js";
import Celular from "../../assets/celular.jpg"
import Loc from "../../assets/loc.svg"
import Camera from "../../assets/camera.svg"
import Relogio from "../../assets/relogio.svg"
import Moeda from "../../assets/moeda.svg"
import Suporte from "../../assets/suporte.svg"
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FiniLogo from "../../assets/finilogo.png";
import AspasEsquerda from "../../assets/aspas-esquerda.png";
import AspasDireita from "../../assets/aspas-direita.png";
import Instagram from "../../assets/instagram-iwatch.svg"
import Linkedin from "../../assets/linkedin-iwatch.svg"
import Whatsapp from "../../assets/whats-iwatch.svg"
import LogoIfollow from "../../assets/logo-ifollow.svg"
import LogoIfollowHeader from "../../assets/logo-ifollow-header.png"
import Pt from "../../assets/pt.png"
import Es from "../../assets/es.svg"
import Balao from "../../assets/balao-iwatch.svg";
import Pin from "../../assets/pin.svg";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { jsonMockudo } from "../../utils/Iwatch/jsonTraducao";
import api from "../../services/api.js";
import InputMask from 'react-input-mask';
import { useEffect } from "react";

const IwatchLanding = () => {
    
    const [idioma, setIdioma] = useState('pt');
    const [loadingApi, setLoadingApi] = useState(false);
    
    const schema = Yup.object().shape({
        nome: Yup.string()
            .required(idioma === "pt" ? 'O Nome é obrigatório' : "El nombre es obligatorio")
            .max(250, idioma === "pt" ? 'O Nome deve ter no máximo 250 caracteres' : "El nombre debe tener como máximo 250 caracteres"),
        email: Yup.string()
            .email(idioma === "pt" ? 'Digite um E-mail válido' : "Ingrese un correo electrónico válido")
            .required(idioma === "pt" ? 'O E-mail é obrigatório' : "El correo electrónico es obligatorio")
            .max(250, idioma === "pt" ? 'O E-mail deve ter no máximo 250 caracteres' : "El correo electrónico debe tener como máximo 250 caracteres"),
        telefone: Yup.string()
            .required(idioma === "pt" ? 'O Telefone é obrigatório' : "El teléfono es obligatorio")
            .max(250, idioma === "pt" ? 'O Telefone deve ter no máximo 250 caracteres' : "El teléfono debe tener como máximo 250 caracteres"),
        empresa: Yup.string()
            .required(idioma === "pt" ? 'A Empresa é obrigatória' : "La empresa es obligatoria")
            .max(250, idioma === "pt" ? 'A Empresa deve ter no máximo 250 caracteres' : "La empresa debe tener como máximo 250 caracteres"),
        mensagem: Yup.string()
            .max(250, idioma === "pt" ? 'A Empresa deve ter no máximo 250 caracteres' : "La empresa debe tener como máximo 250 caracteres")
    });
    
    
    const { register, handleSubmit, formState: {errors}, reset, setValue, control } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = (data) => {
        setLoadingApi(true)
        data.idioma = idioma;
        toast.success("Obrigado pelo seu contato. Seu e-mail foi enviado com sucesso!")
        api.post('/landing_page_iwatch', {data}).then(response => {
            reset({
                nome: "",
                email: "",
                telefone: "",
                empresa: "",
                mensagem: ""
            })
            setValue("telefone", "");
            setLoadingApi(false)
        }
        ).catch(error => {
            toast.error("Erro ao enviar email")
            setLoadingApi(false)
        }
        );
    }

    const traduzir = (texto) => {
        if(idioma === "pt"){
            return texto;
        }

        const traducao = jsonMockudo[texto] && jsonMockudo[texto][idioma];
        if (traducao) {
            return traducao;
        } else {
            return texto; 
        }
    }

    const levarAoForm = () => {
        const elementoClicado = document.getElementsByClassName('form')[0];
        if (elementoClicado){
            elementoClicado.scrollIntoView({
                behavior: "smooth",
                block: "center",
            })
            setTimeout(() => {
                elementoClicado.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                })
            }, 100)}
    }

    useEffect(() => {
        setValue("telefone", idioma === "pt" ? "+55 " : "");
    }, [idioma, control]); 

    return (
        <IwatchLandingStyle>
        { idioma && <>
        <header>
            <div className="width90">
                <img className="logo" src={LogoIfollowHeader}/>
                <div className="div-header-mobile">
                    {idioma === "es" ? <img className="bandeira" src={Pt} onClick={() => setIdioma("pt")}/> : <img className="bandeira" src={Es} onClick={() => setIdioma("es")}/>}
                    <button onClick={() => {levarAoForm()}}>{traduzir("quero para o meu negócio ")}{">"}</button>
                </div>
                <div className="div-header-desktop">
                    <div className="idiomas">
                        <div className="div-cinza-botoes">
                            <button className={idioma === "pt" ? "selecionado" : ""}  onClick={() => setTimeout(() => {
                                setIdioma("pt")
                            }, 10)}>
                                <img src={Pt}/>
                                {traduzir("português")}
                            </button>
                            <button className={idioma === "es" ? "selecionado" : ""} onClick={() => setTimeout(() => {
                                setIdioma("es")
                            }, 10)} >
                                <img src={Es}/>
                                {traduzir("espanhol")}
                            </button>
                        </div>
                    </div>
                    <button onClick={() => {levarAoForm()}}>{traduzir("quero para o meu negócio ")}{">"}</button>
            </div>
        </div>
    </header>
    <div>
        <div className="div-roxa">
            <img className="balao" src={Balao}/>
            <div id="google_translate_element"></div>
            <div className="body">
                <div className="flex-div-roxa">
                    <div className="textos-div-roxa">
                        <h1>{traduzir("Mais controle,")}</h1>
                        <h2>{traduzir("menos custos!")}</h2>
                        <h5>
                            {traduzir("Com o iWatch você acompanha remotamente a")} 
                            <strong>{traduzir(" localização ")}</strong> 
                            {traduzir("dos seus materiais direto pelo celular")}
                        </h5>
                        <button onClick={() => {levarAoForm()}}>{traduzir("saiba mais ")}{">"}</button>
                    </div>
                    <div className="relative-imagem">
                        <img className="celular" src={Celular}/>
                        <div className="div-roxa-redonda desktop">
                            <span>{traduzir("Por apenas")}</span>
                            <div className="flex">
                                <div className="dinheiro">
                                    {idioma === "pt" && <h4>{traduzir("R$")}</h4>}
                                    <h3 className={idioma !== "pt" && "margin-top-dinheiro"}>{traduzir("1")}</h3>
                                </div>
                                <label>*</label>
                                <h5 className={`h5-${idioma}`}>{traduzir("você rastreia o seu material")}</h5>
                                {idioma !== "pt" && <h6>BRL</h6>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="div-roxa-redonda mobile">
                    <span>{traduzir("Por apenas")}</span>
                    <div className="flex">
                        <div className="dinheiro">
                            {idioma === "pt" && <h4>{traduzir("R$")}</h4>}
                            <h3 className={idioma !== "pt" && "margin-top-dinheiro"}>{traduzir("1")}</h3>
                        </div>
                        <label>*</label>
                        <h5 className={`h5-${idioma}`}>{traduzir("você rastreia o seu material")}</h5>
                        {idioma !== "pt" && <h6>BRL</h6>}
                    </div>
                </div>
                <span className="span-custo">{traduzir("* Custo de cada leitura realizada")}</span>
            </div>
        </div>
    </div>
    <div className="cards"> 
        <div className="card">
            <img src={Loc}/>
            <h3><strong>{traduzir("Localização")}</strong> {traduzir("a cada leitura")}</h3>
        </div>
        <div className="card">
            <img src={Camera}/>
            <h3>{traduzir("Pode ser feito por")} <strong>{traduzir(" qualquer dispositivo ")}</strong> {traduzir("com câmera")}</h3>
        </div>
        <div className="card">
            <img src={Moeda}/>
            <h3><strong>{traduzir(" Baixo custo! ")}</strong> {traduzir("Apenas R$1,00 por leitura")}</h3>
        </div>
        <div className="card">
            <img src={Relogio}/>
            <h3><strong>{traduzir("Rápido e fácil")}</strong> {traduzir("de implementar")}</h3>
        </div>
        <div className="card">
            <img src={Suporte}/>
            <h3><strong>{traduzir("Suporte 24/7")}</strong> {traduzir("para toda a equipe")}</h3>
        </div>
    </div>
    <button className="botao-azul" onClick={() => { levarAoForm()}}>{traduzir("quero rastrear meu material ")}{">"}</button>
    <div className="div-ping">
        <img src={Pin}/>
        <h2>{traduzir("+3500")}</h2>
        <h4>{traduzir("materiais rastreados nos últimos meses")}</h4>
    </div>
    <div className="div-form">
        <div className="textos-form">
            <h5 className="h5-form">
                {traduzir("Precisa de mais")} 
                <strong>{traduzir(" visibilidade ")}</strong> 
                {traduzir("dos seus materiais?")}
            </h5>
            <h6 className="h6-form mobile">
                {traduzir("Preencha o formulário abaixo, e um de nossos especialistas entrará em contato para apresentar a solução ideal para sua empresa!")}
            </h6>
            <h6 className="h6-form desktop">
                {traduzir("Preencha o formulário ao lado, e um de nossos especialistas entrará em contato para apresentar a solução ideal para sua empresa!")}
            </h6>
        </div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="relative-input">
                <input type="text" placeholder={traduzir("Nome")} {...register("nome")}/>
                {errors.nome && idioma && <span>{traduzir(errors.nome.message)}</span>}
            </div>
            <div className="relative-input">
                <input type="text" placeholder={traduzir("Empresa")} {...register("empresa")}/>
                {errors.empresa && <span>{traduzir(errors.empresa.message)}</span>}
            </div>
            <div className="relative-input">
                <input type="text" placeholder={traduzir("E-mail")} {...register("email")}/>
                {errors.email && <span>{traduzir(errors.email.message)}</span>}
            </div>
            <div className="relative-input">
            <Controller
                name="telefone"
                control={control}
                defaultValue={idioma ? "+55 " : "+34"}
                render={({ field }) => (
                    <InputMask
                        {...field}
                        mask={idioma === "pt" ? "+99 (99) 99999-9999" : "+99 (999) 99999-9999"}
                        placeholder="+55 (11) 99999-9999"
                    />
                )}
            />
                {errors.telefone && <span>{traduzir(errors.telefone.message)}</span>}
            </div>
            <div className="relative-input">
                <textarea placeholder={traduzir("Mensagem")} {...register("mensagem")}/>
                {errors.mensagem && <span>{traduzir(errors.mensagem.message)}</span>}
            </div>
            <button disabled={loadingApi} type="submit" className="botao-roxo">{traduzir("enviar ")}{">"}</button>
        </form>
    </div>
    <div className="div-cinza">
        <div className="width83">
            <div className="column">
                <h2>{traduzir("Solução")} <strong>{traduzir(" testada e recomendada ")}</strong> {traduzir("por quem usa!")}</h2>
                <div className="nome-fini">
                    <div className="div-img">
                        <img src={FiniLogo}/>
                    </div>
                    <h3>
                        <strong>{traduzir("Victor Henrique")}</strong>
                        {traduzir("Analista de Trade Marketing | Operações")}
                    </h3>
                </div>
            </div>
            <div className="textos">
                <h5>
                    <img className="aspas-esquerda" src={AspasEsquerda}/>
                    {traduzir("A Fini tem muita relevância como marca nos pontos de venda do Brasil e investimos bastante em manter essa fortaleza frente à concorrência através de visibilidade com nossos materiais.")}
                </h5>
                <h5>
                    {traduzir("Até ano de 2023, não tínhamos visão de onde nossos materiais estavam sendo destinados, ou seja, para onde estava indo o investimento da companhia que acabava ficando no limbo?")}
                </h5>
                <h5>
                    <strong>
                        {traduzir(" Quando conhecemos o time da Ifollow, foi como uma sensação de alívio para nós, pois conseguimos pelo menos saber onde estavam sendo positivados nossos displays de sazonalidade Junina. ")} 
                    </strong>
                    {traduzir("Através desse norte, pudemos rever nossas estratégias para as próximas campanhas")}
                </h5>
                <h5>
                    <img className="aspas-direita" src={AspasDireita}/>
                    {traduzir("Deu tão certo esse piloto que estamos fechando parceria de sucesso para demais campanhas de 2024.")}
                </h5>
            </div>
        </div>
    </div>
    <footer>
        <div className="imagens mobile">
        <a target="_blank" href="https://www.instagram.com/ifollowbrasil/">
            <img src={Instagram}/>
        </a>
        <a target="_blank" href="https://br.linkedin.com/company/ifollowbrasil">
            <img src={Linkedin}/>
        </a>
        <a target="_blank" href="https://api.whatsapp.com/send/?phone=5511950853443&text&type=phone_number&app_absent=0">
            <img src={Whatsapp}/>
        </a>
        </div>
        <div className="div-logo">
            <img className="logo-ifollow" src={LogoIfollow}/>
            <span>{traduzir("+55 (11) 2476-2245")}</span>
            <span>{traduzir("mkt@ifollow.com.br")}</span>
        </div>
        <div className="div-final">
            <div className="imagens desktop">
                <a target="_blank" href="https://www.instagram.com/ifollowbrasil/">
                    <img src={Instagram}/>
                </a>
                <a target="_blank" href="https://br.linkedin.com/company/ifollowbrasil">
                    <img src={Linkedin}/>
                </a>
                <a target="_blank" href="https://api.whatsapp.com/send/?phone=5511950853443&text&type=phone_number&app_absent=0">
                    <img src={Whatsapp}/>
                </a>
            </div>
            <span className="width90">{traduzir("iFollow Desenvolvimento e Licenciamento de Software LTDA | CNPJ 34.574.801/0001-82")}</span>
        </div>
    </footer>
    </>}
        </IwatchLandingStyle>
    )
}

export default IwatchLanding;