import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import DigitalExperiencePage from '../pages/DigitalExperiencePage';
import HomePage from '../pages/HomePage';
import ImanagePage from '../pages/ImanagePage';
import ScrollToTop from '../components/ScrollToTop';
import QuemSomosPage from '../pages/QuemSomosPage';
import TailorDevPage from '../pages/TailorDev';
import AnalyticsPage from '../pages/Analytics';
import CasesPage from '../pages/Cases';
import IwatchLandingPage from '../pages/Iwatch';


const Router = () => {
  
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<HomePage />}/>
                <Route path='/imanage' element={<ImanagePage />}/>
                <Route path='/digital-experience' element={<DigitalExperiencePage />}/>
                <Route path='/quem-somos' element={<QuemSomosPage  />}/>
                <Route path='/tailor-dev' element={<TailorDevPage  />}/>
                <Route path='/analytics' element={<AnalyticsPage />}/>
                <Route path='/cases' element={<CasesPage/>}/>
                <Route path='/iwatch' element={<IwatchLandingPage/>}/>
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;