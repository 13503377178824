import axios from 'axios';

// export const baseURL = 'http://localhost:8080';
export const baseURL = 'https://apiimanage.ifollow.com.br';

const api = axios.create({
	baseURL: baseURL,
});

api.interceptors.request.use((config) => {
	const token = localStorage.getItem('token');
	
	if (token) {
		config.headers = {
			Authorization: `Bearer ${token}`
		};
	}

	return config;
});

api.interceptors.response.use((response) => {
	return response;
}, (error) => {
	const token = localStorage.getItem('token');

	if (error.response) {
		if (error.response.status === 401 && token) {
			localStorage.removeItem('token');
			window.location.reload();
		};
	} 
	return Promise.reject(error);
});

export default api;